import CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/gruvbox-dark.css';
import 'codemirror/mode/xml/xml.js';
import emmet from '@emmetio/codemirror-plugin';

emmet(CodeMirror);

const textArea = document.getElementById('article-text-area');
if (textArea) {
  const editor = CodeMirror.fromTextArea(textArea, {
    mode: 'text/html',
    lineNumbers: true,
    lineWrapping: true,
    theme: 'gruvbox-dark',
    extraKeys: {
      Tab: 'emmetExpandAbbreviation',
      Esc: 'emmetResetAbbreviation',
      Enter: 'emmetInsertLineBreak',
    },
  });

  const pageContent = document.getElementsByClassName('page-content')[0];
  const previewBtn = document.getElementById('preview-btn');
  const titleDisplay = document.getElementById('article-title');
  const title = document.getElementById('article-title-area');
  previewBtn.addEventListener('click', (e) => {
    pageContent.innerHTML = editor.getValue();
    titleDisplay.innerHTML = title.value;
  });

  title.addEventListener('keyup', (e) => {
    titleDisplay.innerHTML = title.value;
  });
}

